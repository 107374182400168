import { FC } from 'react';
import Link from 'next/link';
import styles from './HeaderBanner.module.scss';

const HeaderBanner: FC = () => {
  return (
    <div className={styles.litresHeaderBanner}>
      <Link prefetch={false} className={styles.litresHeaderBanner__link} target="_blank" href="https://www.litres.ru/litres_subscription/">
        <img width="1920" height="60" className={styles.litresHeaderBanner__img} src="/images/banners/subscription-1920x60.jpg" alt="banner" />
        {/* <Image priority width={1920} height={60} className={styles.litresHeaderBanner__img} src="/banners/storm-1920x60.jpg" alt="banner" /> */}
        {/* <img className={`${styles.litresHeaderBanner__img} ${styles.litresHeaderBanner__img_tablet}`} src="/banners/storm-970x90.jpg" alt="banner" /> */}
        <img width="450" height="100" className={`${styles.litresHeaderBanner__img} ${styles.litresHeaderBanner__img_mobile}`} src="/images/banners/subscription-450x100.jpg" alt="banner" />
        {/* <Image priority width={450} height={100} className={`${styles.litresHeaderBanner__img} ${styles.litresHeaderBanner__img_mobile}`} src="/banners/storm-450x100.jpg" alt="banner" /> */}
      </Link>
    </div>
  );
};

export default HeaderBanner;
