import { arrayRange } from './helpers';
import { IAlphabetType } from '@/interfaces/alphabet.interface';

const BOOKS_FOR_PAGE = 15;
const GENRES_FOR_PAGE = 30;
const SERIES_FOR_PAGE = 30;
const AUTHORS_FOR_PAGE = 30;
const PRERENDER_PAGES = 2;

const DAYS = arrayRange(1, 31, 1);
const MONTHS = [
  { name: 'Январь', value: '01' },
  { name: 'Февраль', value: '02' },
  { name: 'Март', value: '03' },
  { name: 'Апрель', value: '04' },
  { name: 'Май', value: '05' },
  { name: 'Июнь', value: '06' },
  { name: 'Июль', value: '07' },
  { name: 'Август', value: '08' },
  { name: 'Сентябрь', value: '09' },
  { name: 'Октябрь', value: '10' },
  { name: 'Ноябрь', value: '11' },
  { name: 'Декабрь', value: '12' },
];

const MONTH_NAMES = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

const YEARS = arrayRange(1923, 2018, 1);
const GENDER = [
  { name: 'Мужской', value: 'male' },
  { name: 'Женский', value: 'female' },
];

const ABSOLUTE_URL = 'https://fantasy-worlds.ru';
// const DEV_ABSOLUTE_URL = '';
// Переменная для разработки, абсолютным url - для картинок/фрагментов текста/аудио
const DEV_ABSOLUTE_URL = ABSOLUTE_URL;

const config = {
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Authorization',
  },
};

const API_URL = `${process.env.APP_URL}`;

const REVALIDATE_TIME = 300;

const alphabet: IAlphabetType = {
  ru_a: 'А',
  ru_b: 'Б',
  ru_v: 'В',
  ru_g: 'Г',
  ru_d: 'Д',
  ru_e: 'Е',
  ru_zh: 'Ж',
  ru_z: 'З',
  ru_i: 'И',
  ru_y: 'Й',
  ru_k: 'К',
  ru_l: 'Л',
  ru_m: 'М',
  ru_n: 'Н',
  ru_o: 'О',
  ru_p: 'П',
  ru_r: 'Р',
  ru_s: 'С',
  ru_t: 'Т',
  ru_u: 'У',
  ru_f: 'Ф',
  ru_h: 'Х',
  ru_tz: 'Ц',
  ru_ch: 'Ч',
  ru_sh: 'Ш',
  ru_sz: 'Щ',
  ru_bh: 'Ъ',
  ru_bi: 'Ы',
  ru_bs: 'Ь',
  ru_ie: 'Э',
  ru_yu: 'Ю',
  ru_ia: 'Я',
  en_a: 'A',
  en_b: 'B',
  en_c: 'C',
  en_d: 'D',
  en_e: 'E',
  en_f: 'F',
  en_g: 'G',
  en_h: 'H',
  en_i: 'I',
  en_j: 'J',
  en_k: 'K',
  en_l: 'L',
  en_m: 'M',
  en_n: 'N',
  en_o: 'O',
  en_p: 'P',
  en_q: 'Q',
  en_r: 'R',
  en_s: 'S',
  en_t: 'T',
  en_u: 'U',
  en_v: 'V',
  en_w: 'W',
  en_x: 'X',
  en_y: 'Y',
  en_z: 'Z',
};

const RECAPTCHA_SITE_KEY = `${process.env.RECAPTCHA_SITE_KEY}`;

const LITRES_AUTHORS = ['dem-mihaylov', 'tala-tocka', 'mayk-omer', 'ayrin-laks', 'andrey-usachev', 'lu-cysin'];

const MENU_LINKS = [
  { name: 'Главная', link: '/' },
  { name: 'Новинки', link: '/new' },
  { name: 'Библиотека', link: '/lib' },
  { name: 'Жанры', link: '/genre' },
  { name: 'Cерии', link: '/series' },
  { name: 'Подкасты', link: '/lib/podcasts' },
  { name: 'Комментарии', link: '/comments' },
  { name: 'Обратная связь', link: '/mail' },
];

export {
  BOOKS_FOR_PAGE,
  GENRES_FOR_PAGE,
  AUTHORS_FOR_PAGE,
  DAYS,
  MONTHS,
  YEARS,
  GENDER,
  MONTH_NAMES,
  API_URL,
  PRERENDER_PAGES,
  DEV_ABSOLUTE_URL,
  SERIES_FOR_PAGE,
  REVALIDATE_TIME,
  RECAPTCHA_SITE_KEY,
  LITRES_AUTHORS,
  MENU_LINKS,
  ABSOLUTE_URL,
  config,
  alphabet,
};
